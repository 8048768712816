let  daies = ["一", "二", "三", "四", "五", "六", "日"];

const getWeek = (begin , end) => {
    if( begin == end) {
        return "周" + daies[begin-1];
    } else {
        return "周" + daies[begin-1] + "至" + "周" + daies[end-1];
    }
}

function addOpeningHours(times, open, close) {
    if(!open && !close) {
        return times;
    }
    times.push(open.substring(0,5) + "-" + close.substring(0,5));
    return times;
}

const buildOpeningHours = (state, hours) => {
    state.openingHours = hours;
    if( !hours || hours.length == 0) {
        state.openingTime = state.restaurant.openHours + "-" + state.restaurant.closeHours;
    } else {
        let openingHours = [];
        for( let i = 0 ; i < hours.length ; i++) {
            let opening = hours[i];
            let openingTimeStr = getWeek(opening.begin , opening.end);
            let times = [];
            times = addOpeningHours(times, opening.open , opening.close);
            times = addOpeningHours(times,opening.open2 , opening.close2);
            times = addOpeningHours(times,opening.open3 , opening.close3);
            openingTimeStr += times.join(" ");
            openingHours.push(openingTimeStr);
        }
        state.openingTime = openingHours.join(" ");
    }
}

export default {
    buildOpeningHours
}