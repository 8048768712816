<template>
  <view class="index restaurant-detail">
    <!-- <van-nav-bar
      title="餐厅详情"
      left-arrow
      @click-left="onBack"
    /> -->

    <van-swipe :autoplay="4000">
      <van-swipe-item v-for="imgUrl in images" :key="imgUrl">
        <van-image
          width="100%"
          height="180px"
          fit="cover"
          lazy-load  
          :src="resetImageUrl(imgUrl,0,0)"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="detail-info">
      <div class="rest-name"> {{ restaurant.name }}</div>
      <div class="rest-open">
        <van-icon name="clock-o" color="#000" />
          <span>{{openingTime}}</span>
      </div>
      <div class="rest-address">
        <van-icon name="location-o" color="#000" />{{restaurant.cityName}}{{ restaurant.address }}
      </div>
    </div>
    
    <div class="restaurant-introduce">
      <van-cell @click="onShowIntroView" is-link title="餐厅介绍"></van-cell>
    </div> 


    <h2>商家招牌菜（{{restaurantDishsSize()}}）</h2>
    <van-swipe :autoplay="4000" v-if="restaurantDishsSize() > 0">
        <van-swipe-item v-for="dish in restaurant.dishs" :key="dish.id">
          <div class="dish-info">
            <div class="dish-header">
              <div class="dish-name">{{dish.name}}</div>
              <div class="diash-action" v-if="dish.expirationTimeSeconds > 0">
                <!-- <span>距截止</span>
                <van-count-down time="{{ time }}" /> -->
                <van-button class="btn-order" @click="onDishDetails(dish.id)">购买招牌菜</van-button>
              </div>
            </div>
            <van-image width="100%" height="180px" fit="cover" 
              lazy-load  
              :src="resetImageUrl(dish.imageUrl,0,0)"
              @click="onDishDetails(dish.id)" />
          </div>
        </van-swipe-item>
    </van-swipe>

    <div class="restaurant-bottom"></div> 


    <van-action-sheet v-model:show="showIntro" title="餐厅介绍">
        <div class="content">{{restaurant.intro}}</div>
    </van-action-sheet>
    <!-- <van-cell is-link title="限制条件与细则" @click="onRuleView" />
    <van-action-sheet v-model:show="ruleShow" title="限制条件与细则">
        <div>限制条件与细则内容限制条件与细则内容限制条件与细则内容限制条件与细则内容,限制条件与细则内容限制条件与细则内容,限制条件与细则内容,限制条件与细则内容限制条件与细则内容</div>
    </van-action-sheet> -->

    <div class="resturant-action">
      <!-- <van-icon name="share-o" @click="onShare" /> 
      <van-icon name="phone-o" @click="onCall" /> -->
      <!-- <span></span>
      <span></span> -->
      <van-button class="btn-order" @click="onBooking"> <van-icon name="completed" /> 预定餐厅 </van-button>
    </div>
    <!-- <van-share-sheet
        v-model:show="showShare"
        title="立即分享给好友"
        :options="shareOptions"
        @select="onShareSelect"
    /> -->
  </view>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter} from "vue-router";
import { loading , clearLoading} from "../utils/loading";
import RestaurantService from "../api/restaurant-service";
import RestaurantOpeningHours from "./restaurant-opening-hours";
import { resetImageUrl} from "../utils";
import "../css/restaurant-details.scss";

export default {
  setup() {
    const state = reactive({
      restaurant: {},
      // ruleShow: false,
      showIntro: false,
      // showShare: false,
      images: [],
      openingHours: null,
      openingTime: null
    });
    const router = useRouter();
    const { id } = useRoute().params;
    const onShowIntroView = () => state.showIntro = true
    // const onRuleView = () => state.ruleShow = true
    // const onCall =  () => window.location.href = "tel:" + state.restaurant.phone
    // const onShare = () => state.showShare = true;
    // const onShareSelect = ()=> true;
    const onBooking =()=> router.push("/restaurant/" + state.restaurant.id + "/booking");
    const onDishDetails = id => router.push("/dish/" + id);
    // const onBack =()=> router.push("/restaurant");
    const restaurantDishsSize = () => state.restaurant.dishs ? state.restaurant.dishs.length : 0; 
    onMounted(() => {
      loading()
      RestaurantService.getRestaurantDetails(id).then(r => {
        state.restaurant = r;
        state.images = [r.imageUrl];
        r.images.forEach(url => state.images.push(url))
        clearLoading()
        return RestaurantService.getRestaurantOpeningHours(id)
      } , clearLoading)
      .then( hours =>  RestaurantOpeningHours.buildOpeningHours(state, hours))
    });
    return {
      ...toRefs(state),
      onShowIntroView,
      // onRuleView,
      // onCall,
      // onShare,
      // onShareSelect,
      onBooking,
      // onBack,
      onDishDetails,
      restaurantDishsSize,
      resetImageUrl
    };
  },
};
</script>
